import { useState, useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getViewData, getPublicViewData } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';

const fallbackTheme = process?.env?.SITE_NAME || 'Medborgarskolan';

export default function DynamicNotFoundPage() {
    // 404 does not support getServerSideProps, must fetch client side data
    // https://github.com/vercel/next.js/blob/master/errors/404-get-initial-props.md
    const [data, setData] = useState(null);
    useEffect(() => {
        async function fetchData() {
            const hostname = window.location.hostname;
            const params = { host: hostname };
            const { json: pageData } = await getPublicViewData('404', params);
            setData(pageData);
        }
        fetchData();
    }, []);

    useEffect(() => {
        const currentTheme = data?.componentProps?.currentTheme || '';
        if(currentTheme && !document.body.classList.contains(`theme-${currentTheme}`)) {
            document.body.classList.add(`theme-${currentTheme}`);
        }
    }, [data]);

    if (!data) {
        return null;
    }

    return <NotFoundPage {...data} />;
}

function NotFoundPage({ componentName, componentProps }) {
    const Component = LazyContainers[componentName];
    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }
    return <Component {...componentProps} />;
}

export const getStaticProps = async ({ locale = 'sv', ...restProps }) => {
    const namespace = fallbackTheme.toLowerCase();
    const i18n = await serverSideTranslations(locale, [
        ...(namespace ? [namespace] : []),
        ...(namespace && namespace === 'pahlmansgy' ? ['pahlmans'] : []),
        'default',
    ]);

    return {
        props: {
            ...i18n,
        },
    }
};

/*
// For static routing
export async function getStaticProps({ params, preview, previewData }) {
  const pageData = await getViewData('404');
  return { props: pageData }
}
*/
